body {
    background-color: #faf3e1;
}

.banner {
    position: relative;
    margin-bottom: 20px;
}

.banner-overlay {
    position: absolute;
}

.grid-row {
    margin-bottom: 20px;
}

.clickable-image {
    cursor: pointer;
}

.carousel {
    display: flex;
    overflow-x: scroll;
}

.carousel-content {
    align-self: center;
    margin: 10px;
}

.kamer1-color {
    background-color: #a8875e;
    color: white;
}

.kamer2-color {
    background-color: #96454c;
    color: white;
}

.kamer3-color {
    background-color: #706c7b;
    color: white;
}

.kamer4-color {
    background-color: #895b4c;
    color: white;
}

.kamer5-color {
    background-color: #2d6451;
    color: white;
}

.kamer-padding {
    padding: 20px;
}